import * as GL from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'
import { SVGLoader } from '@/lib/three/SVGLoader'

const svgText = `
<svg viewBox="0 0 100 115.4">
  <path d="M.1456,67.4582C.3891,54.7987.1456,42.1392.1456,29.2362c0-.7304.2435-.9738,1.2173-1.7042,11.6857-6.3298,23.8583-13.3899,35.544-20.2065,3.8952-2.4345,8.2774-4.3821,12.1726-7.0601.4869-.4869.7304-.2435.9738.2435,3.1649,5.8429,6.5732,11.6857,9.9815,17.5286.2435.2435.7304.7304.2435.9738-2.678-1.4607-5.1125-3.1649-7.547-4.3821-.7304-.7304-1.7042-.7304-2.678-.2435-3.4083,1.7042-6.8167,3.4083-10.225,5.1125-1.4607,1.2173-.7304-.4869-.2435-1.2173,2.678-5.356,6.3298-10.4685,8.7643-15.5809-4.3821,6.0863-9.0077,12.1726-13.3899,18.2589-1.2173,1.7042-2.678,2.678-4.3821,3.4083-6.3298,3.1649-12.6595,6.5732-18.9893,9.4946-.4869.2435-.7304.4869-.7304.9738v16.0679c0,1.7042-.4869,3.1649-1.2173,4.3821-1.7042,2.4345-3.4083,4.6256-5.1125,6.8167-1.2173,1.7042-2.678,3.4083-3.6518,5.356,0,.2435-.2435.2435-.7304,0,0,.2434,0,0,0,0Z"/>
  <path d="M51.7575,34.3487c2.9214,1.9476,6.0863,3.6518,9.0077,5.356.7304.2435.9738.7304,0,1.2173-7.3036,3.6518-14.3637,7.547-21.6673,10.9554-.7304.2435-.7304.7304-.7304,1.4607v12.1726c0,.4869.2435.7304.7304.9738,3.4083,1.9476,6.5732,3.8952,9.9815,5.8429.7304.4869,1.2173.4869,1.7042,0,5.8429-3.1649,11.4423-6.3298,17.2851-9.2512,1.2173-.4869,3.8952-2.4345,3.6518-1.2173v7.7905c0,.4869-.2435.4869-.4869.7304-5.356,3.4083-10.4685,6.8167-15.8244,9.9815-1.7042.7304-3.4083,3.4083-5.356,2.1911-6.5732-3.8952-13.3899-7.547-20.2065-11.1988-1.2173-.7304-1.7042-1.4607-1.7042-2.9214v-22.3976c0-.4869.2435-.7304.7304-.9738,6.8167-3.6518,13.8768-6.8167,20.6934-10.7119,1.2173-.4869,1.7042-.4869,2.1911,0Z"/>
  <path d="M72.2075,48.9559c0,3.1649-.7304,3.4083-2.678,4.3821-6.0863,3.1649-12.4161,6.3298-18.5024,9.4946-1.2173.7304-2.4345-.2435-3.8952-.7304-.7304-.2435-.9738-.7304-.7304-1.7042.7304-1.2173.4869-3.1649,1.7042-3.8952,7.3036-3.4083,14.1202-7.547,21.4238-10.9554,1.2173-.7304,2.678.2435,2.678,1.4607"/>
  <path d="M80.2414,95.6987c1.2173,0,2.1911.2434,3.4083.4869-2.9214,1.7042-5.5994,3.4083-8.5208,4.869-8.0339,4.6256-16.0679,9.2512-24.1018,14.1202-.4869.2434-.9738.2434-1.4607,0-6.5732-3.8952-13.1464-7.547-19.7196-11.4423-9.7381-5.356-19.2327-11.4423-29.2143-16.7982-.7304-.2434-.7304-.7303-.4869-1.2173,1.2173-1.7042,1.9476-3.4083,2.9214-5.1125,2.4345-4.1387,4.869-8.5208,7.3036-12.6595-.2435,3.8952-.2435,7.7905-.2435,11.6857,0,.7304.2435.9738.7304,1.2173,1.4607.7304,3.1649,1.4607,4.6256,2.4345,2.1911,1.2173,4.1387,2.4345,6.5732,3.4083-6.8167,0-13.3899,0-20.2065.2434,4.1387.9738,8.2774.9738,12.1726,1.4607,4.3821.4869,9.0077.4869,13.3899,1.4607,7.3036,3.4083,14.3637,7.7905,21.6673,11.6857,1.2173.7304,2.1911,1.4607,3.6518.9738,4.869-2.4345,9.0077-6.3298,13.8768-8.2774,4.869.4869,9.2512.7304,13.6333,1.4607Z"/>
  <path d="M99.2307,28.2624c.4869.2435.7304.4869.7304,1.2173v56.7244c0,.2434.2434.7304-.4869.7304h-21.6673c3.8952-2.1911,7.3036-4.6256,10.9554-7.0601.7304-.4869.9738-.9738.9738-1.9476-.2434-2.9214.2434-5.8429,0-8.7643,2.678,4.6256,5.356,9.0077,8.0339,13.6333,1.4607,2.678,0-1.4607-.4869-2.1911-2.1911-4.3821-4.1387-8.7643-6.3298-13.1464-.7304-1.4607-.9738-2.9214-.9738-4.6256.2434-8.2774.2434-16.7982.4869-25.0756,0-1.4607-.2434-1.9476-1.4607-2.678-5.5994-2.9214-10.9554-6.0863-16.3113-9.2512-.7304-.4869-1.2173-.9738-1.4607-1.7042-1.2173-2.9214-2.678-5.5994-3.8952-8.5208-1.2173-2.678-2.4345-5.1125-3.8952-8.0339,1.2173.2435,1.7042.9738,2.4345,1.4607l33.353,19.2327Z"/>
</svg>
`

const loader = new SVGLoader()
const svgData = loader.parse(svgText)

const shapes = []

// Loop through each SVG path and convert to shapes
svgData.paths.forEach(path => {
  const pathShapes = path.toShapes(true)

  shapes.push(...pathShapes)
})

// Create a ShapeGeometry for each shape
const geometries = shapes.map(shape => new GL.ShapeGeometry(shape))

// Merge all geometries into one
const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries)

// --- Scale and flip the geometry ---
// Compute the bounding box of the merged geometry
mergedGeometry.computeBoundingBox()

const bbox = mergedGeometry.boundingBox
const geoWidth = bbox.max.x - bbox.min.x

// Assume you want the new logo to be roughly 1 unit wide (as with your original)
// Compute a scale factor based on the SVG viewBox (width of 100) and desired width
const scaleFactor = 1 / geoWidth

mergedGeometry.scale(scaleFactor, scaleFactor, 1)

// The original merged geometry was flipped along the y-axis:
mergedGeometry.scale(1.5, -1.5, 1.5)

// Optionally, center the geometry (if your original was centered)
mergedGeometry.computeBoundingBox()

const centerX = (mergedGeometry.boundingBox.min.x + mergedGeometry.boundingBox.max.x) / 2
const centerY = (mergedGeometry.boundingBox.min.y + mergedGeometry.boundingBox.max.y) / 2

mergedGeometry.translate(-centerX, -centerY, 0)

export default mergedGeometry
